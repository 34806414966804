<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="titleBox">
        <span class="title">Capital Para el Desarrollo Productivo, S.A. de C.V., Sofom, E.N.R.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li2">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Sobre Nosotros</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li4">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Acerca de los productos</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/protocol' }" class="Li2">
          <router-link to="/protocol" class="nav-link3">
            <span class="routeText3">Problema Comun</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/questions' }" class="Li4">
          <!-- <router-link to="/questions" class="nav-link4">
            <span class="routeText4"></span>
          </router-link> -->
          <a href="https://pemiw.oreidaramx.com/help/privacy_agreement" class="nav-link3" target="_blank">
            <span class="routeText4">Acuerdo de Privacidad</span>
          </a>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li5">
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Thỏa thuận quyền riêng tư</span>
          </router-link>
        </li> -->
         <!-- <li
          :class="{
            'active-link': ['/questions', '/protocol'].includes($route.path),
          }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">Vấn đề Thường Gặp</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li5">
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de privacidad</span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  /* position: sticky;  */
  top: 0;  /* 位于页面顶部 */
  z-index: 1000;  /* 确保导航栏在其他元素上方 */
  height: 100px;
  /* margin-left: 15px; */
}
.secondBox {
  display: flex;
  width: 98%;
  justify-content: space-between;
  
  height: 100px;
  padding: 0 15px;
  background-color: #2A82FF;
  border-radius: 50px;
}





.title {
  width: 20px;
  height: 24px;
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 23px;
  padding-left: 17px;
  /* text-transform: uppercase; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #ffffff;
  line-height: 29px;
  white-space:nowrap;
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;

  align-items: center;
  /* 垂直居中对齐 */
  justify-content: start;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 65px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}
/* .Li1 {
  width: 160px;
}
.Li2 {
  width: 200px;
}
.Li3 {
  width: 220px;
}
.Li4 {
  width: 250px;
}
.Li5 {
  width: 280px;
} */
/* .Li5 {
  width: 250px;
} */
/* 这个如果给margin-right底部可能会出现边距的问题 */
/* .Li4 {
  width: 250px;
  
} */


.active-link {
  /* background: linear-gradient(45deg, #F59120, #F95C40); */
  background-color:#ffffff ;
  /* border-color: #dae633; */
  border-radius: 30px;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;

  color: #2A82FF;
}

.titleBox{
  min-width: 550px;
  line-height: 100px;
}
</style>