<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400 img1Box">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
   

 
    <!-- <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div> -->
  
   

  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/paraeldinero/components/img/3_1.png";



// import importedImg4 from "@/page/paraeldinero/components/img/1_6.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
  // importedImg4: importedImg4,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  /* background-color: #F3F3F3; */
  background-color: white;
}

/* .imgBox1,
.imgBox2,
.imgBox4 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
} */
.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
/* .img1,
.img2,
.img4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
} */

.img1,
.img2,
.img3,
.img4,
.img5,
.img6 {
  margin-left: 45px;
  margin-right: 45px;
  width: 100%;
}
/* .imgItem1,
.imgItem2,
.imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
} */

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  overflow: hidden;
}
.imgItem1{
margin-top: 50px;
margin-bottom: 100px;
}
.imgItem3 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.imgItem5{
  margin-bottom: 100px;
}
.imgItem1,
.imgItem2{
  margin: 0 45px;
  /* width: 1200px; */
  /* margin-left: 200px; */
}
/* .imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
margin-bottom: 100px;
} */
.img1Box{
  display: flex;
  justify-content: center;
  padding: 60px 60px 0 60px;
}
</style>
